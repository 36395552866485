/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import BreakpointWrapper from 'components/BreakpointWrapper';
import Link from 'components/Link';
import PocketConciergeLogo from 'components/PocketConciergeLogo';
import useTimeout from 'hooks/useTimeout';
import HeaderUserMenu from './HeaderUserMenu';
import styles from './styles.module.css';

export type HeaderProps = {
    isAuthenticatedPage?: boolean;
    isFullWidth?: boolean;
    isSessionDisabled?: boolean;
};

const Header: FC<HeaderProps> = ({
    isAuthenticatedPage,
    isFullWidth,
    isSessionDisabled,
}) => {
    const {t} = useTranslation();

    const show = useTimeout(10);

    return (
        <header
            className={clsx(
                'bg-body sticky top-0 z-50 border-b border-grey-200 pb-px dark:border-grey-800',
                styles.shadow
            )}
        >
            <BreakpointWrapper
                breakpoint="md"
                className="container mx-auto px-4 sm:max-w-screen-md sm:px-6"
                wrapBelowBreakpoint={true}
            >
                <div
                    className={clsx(
                        isFullWidth
                            ? 'lg:site-container md:max-w-screen-lg md:px-6'
                            : 'md:site-container'
                    )}
                >
                    <div className="mx-auto flex h-[3.25rem] max-h-[3.25rem] min-h-[3.25rem] items-center justify-between py-1.5">
                        <h1 className="w-36">
                            <Link aria-label={t('siteName')} to="">
                                <PocketConciergeLogo className="dark:brightness-[100]" />
                            </Link>
                        </h1>
                        {!isSessionDisabled && show && (
                            <HeaderUserMenu
                                isAuthenticatedPage={isAuthenticatedPage}
                            />
                        )}
                    </div>
                </div>
            </BreakpointWrapper>
        </header>
    );
};

export default Header;
