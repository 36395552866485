import {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import DateSelector, {
    DateSelectorChangeEvent,
} from 'components/Search/DateSelector';
import {addDays, set, subDays} from 'date-fns';
import {useLocale} from 'state/locale';
import {fromJST, toJST, toTime} from 'utils/date';

type Props = {
    date: Date;
    onSelect: (value: Date) => void;
    waitlistDeadline: Date | undefined;
};

const WaitlistDeadline: FC<Props> = ({date, onSelect, waitlistDeadline}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    const onChangeDeadline = useCallback(
        (event: DateSelectorChangeEvent) => {
            onSelect(event.value as Date);
        },
        [onSelect]
    );

    const now = toJST(new Date());
    const time = toTime(
        // toTime does a implicit conversion to JST. Therefore we need to re-convert it before parsing
        fromJST(set(now, {hours: 15, minutes: 0, seconds: 0})),
        locale
    );
    const minDate = addDays(now, 1);
    const maxDate = subDays(date, 1);

    return (
        <fieldset className="pb-4">
            <legend className="pb-1 text-sm font-semibold">
                {t('reserve.confirmation.waitlist.deadline')}
            </legend>
            <div className="flex">
                <DateSelector
                    className="w-full"
                    data-dd-action-name="WaitlistDeadline"
                    id="waitlist-deadline"
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={onChangeDeadline}
                    value={waitlistDeadline}
                />
            </div>
            <div className="mt-1 text-xs">
                {t('venue.tabs.courses.waitlistDeadlineWarning', {time})}
            </div>
        </fieldset>
    );
};

export default WaitlistDeadline;
